export const ProductType = Object.freeze({
    DIGITAL_DOWNLOAD: 'digital_download',
    ONLINE_COURSE: 'online_course',
    BUNDLE: 'bundle',
    MERCH: 'merch',
    PRIVATE_SESSION: 'private_session',
    WEBINAR: 'webinar',
    MEMBERSHIP: 'membership',
});

export const ProductStatus = Object.freeze({
    PUBLISHED: "published",
    ARCHIVED: "archived",
    DRAFT: "draft",
});

export const SocialPlatform = Object.freeze({
    FACEBOOK: "facebook",
    YOUTUBE: "youtube",
    INSTAGRAM: "instagram",
    TWITTER: "twitter",
    X: "x",
    DISCORD: "discord",
    SLACK: "slack",
    WHATSAPP: "whatsapp",
    TELEGRAM: "telegram",
    TIKTOK: "tiktok",
    QUORA: "quora",
    REDDIT: "reddit",
    LINKEDIN: "linkedin",
    SNAPCHAT: "snapchat",
});

export const SocialitePlatform = Object.freeze({
    GOOGLE: "google",
    FACEBOOK: "facebook",
    YOUTUBE: "youtube",
    INSTAGRAM: "instagram",
    TWITTER: "twitter",
    X: "x",
    DISCORD: "discord",
    SLACK: "slack",
    WHATSAPP: "whatsapp",
    TELEGRAM: "telegram",
    TIKTOK: "tiktok",
    QUORA: "quora",
    REDDIT: "reddit",
    LINKEDIN: "linkedin",
    SNAPCHAT: "snapchat",
});

export const ContentNiche = Object.freeze({
    DIGITAL_MARKETING: "digital_marketing",
    RELATIONSHIPS: "relationships",
    COMEDY: "comedy",
    MOTIVATION: "motivation",
    EDUCATION: "education",
    BUSINESS: "business",
    FINANCE: "finance",
    FASHION: "fashion",
    MUSIC: "music",
    CRYPTOCURRENCY: "cryptocurrency",
    TECHNOLOGY: "technology",
    FOOD: "food",
    HEALTH: "health",
    SPORTS: "sports",
    GAMING: "gaming",
    PETS: "pets",
    REAL_ESTATE: "real_estate",
    TRAVEL: "travel",
});

export const OrderStatus = Object.freeze({
    PENDING: "pending",
    FAILED: "failed",
    PROCESSING: "processing",
    COMPLETED: "completed",
    ON_HOLD: "on-hold",
    CANCELLED: "cancelled",
    REFUNDED: "refunded",
});

export const PayoutMode = Object.freeze({
    AUTOMATIC: "automatic",
    MANUAL: "manual",
});

export const PaymentMethod = Object.freeze({
    FLUTTERWAVE: "flutterwave",
    PAYPAL: "paypal",
});

export const PaymentType = Object.freeze({
    ACCOUNT_BALANCE: "account_balance",
    BANK_TRANSFER: "bank_transfer",
    DIRECT_DEBIT: "direct_debit",
    PAYPAL: "paypal",
    CHEQUE: "cheque",
    CARD: "card",
    USSD: "ussd",
});

export const ModuleType = Object.freeze({
    QUIZ: "quiz",
    LECTURE: "lecture",
    ASSIGNMENT: "assignment",
    RESOURCES: "resources",
});

export const CourseLevel = Object.freeze({
    BEGINNER: 'beginner',
    INERMEDIATE: 'intermediate',
    EXPERT: 'expert',
});
